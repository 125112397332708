import { apiSlice } from "./apiSlice";
import { checkoutSlice } from "../features/checkout/checkoutSlice";
import { solutionMenuSlice } from "../features/solutionMenu/solutionMenuSlice";
import { navbarSlice } from "../features/navbar/navbarSlice";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    checkout: checkoutSlice.reducer,
    solutionMenu: solutionMenuSlice.reducer,
    navbar: navbarSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
