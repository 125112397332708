exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-giancoli-physics-6th-edition-chapters-tsx": () => import("./../../../src/pages/giancoli-physics-6th-edition-chapters.tsx" /* webpackChunkName: "component---src-pages-giancoli-physics-6th-edition-chapters-tsx" */),
  "component---src-pages-giancoli-physics-7th-edition-chapters-tsx": () => import("./../../../src/pages/giancoli-physics-7th-edition-chapters.tsx" /* webpackChunkName: "component---src-pages-giancoli-physics-7th-edition-chapters-tsx" */),
  "component---src-pages-giancoli-physics-7th-global-edition-chapters-tsx": () => import("./../../../src/pages/giancoli-physics-7th-global-edition-chapters.tsx" /* webpackChunkName: "component---src-pages-giancoli-physics-7th-global-edition-chapters-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-manual-approval-tsx": () => import("./../../../src/pages/manual-approval.tsx" /* webpackChunkName: "component---src-pages-manual-approval-tsx" */),
  "component---src-pages-node-6th-edition-video-path-alias-tsx": () => import("./../../../src/pages/{Node6thEditionVideo.path__alias}.tsx" /* webpackChunkName: "component---src-pages-node-6th-edition-video-path-alias-tsx" */),
  "component---src-pages-node-7th-edition-video-path-alias-tsx": () => import("./../../../src/pages/{Node7thEditionVideo.path__alias}.tsx" /* webpackChunkName: "component---src-pages-node-7th-edition-video-path-alias-tsx" */),
  "component---src-pages-node-7th-global-edition-video-path-alias-tsx": () => import("./../../../src/pages/{Node7thGlobalEditionVideo.path__alias}.tsx" /* webpackChunkName: "component---src-pages-node-7th-global-edition-video-path-alias-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-request-password-reset-tsx": () => import("./../../../src/pages/request-password-reset.tsx" /* webpackChunkName: "component---src-pages-request-password-reset-tsx" */),
  "component---src-pages-sign-up-checkout-tsx": () => import("./../../../src/pages/sign-up/checkout.tsx" /* webpackChunkName: "component---src-pages-sign-up-checkout-tsx" */),
  "component---src-pages-sign-up-password-tsx": () => import("./../../../src/pages/sign-up/password.tsx" /* webpackChunkName: "component---src-pages-sign-up-password-tsx" */),
  "component---src-pages-sign-up-thank-you-tsx": () => import("./../../../src/pages/sign-up/thank-you.tsx" /* webpackChunkName: "component---src-pages-sign-up-thank-you-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-subscription-billing-history-[id]-tsx": () => import("./../../../src/pages/subscription/billing-history/[id].tsx" /* webpackChunkName: "component---src-pages-subscription-billing-history-[id]-tsx" */),
  "component---src-pages-subscription-switch-to-annual-[id]-tsx": () => import("./../../../src/pages/subscription/switch-to-annual/[id].tsx" /* webpackChunkName: "component---src-pages-subscription-switch-to-annual-[id]-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */),
  "component---src-pages-user-change-email-tsx": () => import("./../../../src/pages/user/change-email.tsx" /* webpackChunkName: "component---src-pages-user-change-email-tsx" */),
  "component---src-pages-user-change-password-tsx": () => import("./../../../src/pages/user/change-password.tsx" /* webpackChunkName: "component---src-pages-user-change-password-tsx" */),
  "component---src-pages-user-change-username-tsx": () => import("./../../../src/pages/user/change-username.tsx" /* webpackChunkName: "component---src-pages-user-change-username-tsx" */),
  "component---src-pages-user-reset-password-tsx": () => import("./../../../src/pages/user/reset-password.tsx" /* webpackChunkName: "component---src-pages-user-reset-password-tsx" */),
  "component---src-pages-user-settings-tsx": () => import("./../../../src/pages/user/settings.tsx" /* webpackChunkName: "component---src-pages-user-settings-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

