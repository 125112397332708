import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as tags from "./tags";
import toast from "react-hot-toast";

export const sessionTokenQuery: FetchArgs = {
  url: `session/token`,
  responseHandler: "text",
};

type MutationHeaders = {
  "Content-Type": string;
  "X-CSRF-Token"?: string;
};

export const getMutationHeaders = ({
  type,
  sessionToken,
}: {
  type: "json" | "jsonapi";
  sessionToken?: string;
}): MutationHeaders => {
  let headers: MutationHeaders = {
    "Content-Type":
      type === "jsonapi" ? "application/vnd.api+json" : "application/json",
  };
  if (sessionToken) {
    headers["X-CSRF-Token"] = sessionToken;
  }
  return headers;
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`.
 */
export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === "object" && error != null && "status" in error;
};

interface JsonApiError {
  data: {
    errors: {
      id?: string;
      status?: string;
      code?: string;
      title?: string;
      detail: string;
      source?: {
        pointer?: string;
        parameter?: string;
      };
      meta: any;
    }[];
    jsonapi: {
      meta: any;
      version: string;
    };
  };
  status: number;
}
/**
 * Type predicate to narrow an error object to a JsonApi error.
 */
export const isJsonApiError = (
  error: FetchBaseQueryError
): error is JsonApiError => {
  return typeof error.data === "object" && "jsonapi" in error.data;
};

interface JsonError {
  data: {
    message: string;
  };
  status: number;
}
export const isJsonError = (error: FetchBaseQueryError): error is JsonError => {
  return typeof error.data === "object" && "message" in error.data;
};

/**
 * Type predicate to narrow an unknown error to an object with a string `message` property.
 */
export const isErrorWithMessage = (
  error: unknown
): error is { message: string } => {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
};

export const handleErrorResponse = (rejected: unknown): string[] => {
  let errorMessages = [];
  console.error(rejected);
  if (isFetchBaseQueryError(rejected)) {
    if (isJsonApiError(rejected)) {
      rejected.data.errors.forEach((error) => {
        if (error?.detail.length < 1) {
          toast.error(error.title);
          errorMessages.push(error.title);
          return;
        }
        if (typeof error?.source?.pointer === "string") {
          const toRemove = error.source.pointer.split("/").pop();
          const errMsg = error.detail.split(`${toRemove}: `).pop();
          toast.error(errMsg);
          errorMessages.push(errMsg);
        } else {
          toast.error(error.detail);
          errorMessages.push(error.detail);
        }
      });
    } else if (isJsonError(rejected)) {
      toast.error(rejected.data.message);
      errorMessages.push(rejected.data.message);
    } else {
      const errMsg =
        "error" in rejected ? rejected.error : "An unknown error occurred";
      toast.error(errMsg);
      errorMessages.push(errMsg);
    }
  } else if (isErrorWithMessage(rejected)) {
    toast.error(rejected.message);
    errorMessages.push(rejected.message);
  }
  return errorMessages;
};

// Find the other endpoints in slice files [feature]ApiSlice.ts within features folder.
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_DRUPAL_BASE_URL,
    credentials: "include",
  }),
  tagTypes: [
    tags.IS_AUTHENTICATED,
    tags.NODE,
    tags.SESSION_TOKEN,
    tags.USER,
    tags.SUBSCRIPTION,
    tags.COMMENT,
  ],
  endpoints: (builder) => ({
    node: builder.query<any, { contentType: string; nodeUuid: string }>({
      query: ({ contentType, nodeUuid }) => ({
        url: `jsonapi/node/${contentType}/${nodeUuid}`,
      }),
      providesTags: [tags.NODE],
    }),
  }),
});

export const { useNodeQuery } = apiSlice;
