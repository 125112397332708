import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SolutionMenuState {
  scrollTop: {
    [chapterTid: number]: number;
  };
}

const initialState: SolutionMenuState = {
  scrollTop: {},
};

export const solutionMenuSlice = createSlice({
  name: "solutionMenu",
  initialState,
  reducers: {
    onScroll(
      state,
      action: PayloadAction<{ chapterTid: number; scrollTop: number }>
    ) {
      const chapterTid = action.payload.chapterTid;
      const scrollTop = action.payload.scrollTop;
      state.scrollTop[chapterTid] = scrollTop;
    },
  },
});

export const { onScroll } = solutionMenuSlice.actions;
