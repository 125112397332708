import React, { useEffect } from "react";
import { useCurrentUserQuery } from "../features/user/userApiSlice";
import * as Sentry from "@sentry/gatsby";

const SentryComponent = (): null => {
  const { data: currentUser } = useCurrentUserQuery();

  useEffect(() => {
    if (currentUser?.is_authenticated === true) {
      Sentry.setUser({id: String(currentUser.data.uid), username: currentUser.data.name});
    } else {
      Sentry.setUser(null);
    }
  }, [currentUser?.is_authenticated, currentUser?.data?.uid]);

  return null;
};

export default SentryComponent;
