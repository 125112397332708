import {
  apiSlice,
  getMutationHeaders,
  sessionTokenQuery,
} from "../../app/apiSlice";
import * as tags from "../../app/tags";
import { User } from "../../types";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      query: ({ email, pass, marketingConsent }) => ({
        url: `user/register?_format=json`,
        method: "POST",
        headers: getMutationHeaders({
          type: "json",
        }),
        body: {
          mail: {
            value: email,
          },
          pass: {
            value: pass,
          },
          field_marketing_consent: {
            value: marketingConsent,
          },
        },
      }),
      invalidatesTags: [tags.IS_AUTHENTICATED],
    }),
    currentUser: builder.query<
      | { is_authenticated: true; data: User }
      | { is_authenticated: false; data: null },
      void
    >({
      query: () => ({
        url: `current-user`,
      }),
      providesTags: [tags.IS_AUTHENTICATED, tags.USER],
    }),
    isAuthenticated: builder.query<boolean, void>({
      query: () => ({
        url: `user/login_status?_format=json`,
      }),
      providesTags: [tags.IS_AUTHENTICATED],
      transformResponse: (response) => (response === 1 ? true : false),
    }),
    login: builder.mutation<void, { name: string; pass: string }>({
      query: (params) => ({
        url: `user/login?_format=json`,
        method: "POST",
        headers: getMutationHeaders({ type: "json" }),
        body: params,
      }),
      invalidatesTags: [tags.IS_AUTHENTICATED],
    }),
    logout: builder.mutation<string, void>({
      async queryFn(args, api, extraOptions, baseQuery) {
        // Get session token.
        const sessionTokenResult = await baseQuery(sessionTokenQuery);
        if (sessionTokenResult.error) throw sessionTokenResult.error;
        const sessionToken = sessionTokenResult.data as string;

        // Get logout token.
        const logoutTokenResult = await baseQuery({
          url: `session/token/logout?_format=json`,
        });
        if (logoutTokenResult.error) throw logoutTokenResult.error;
        const logoutTokenData = logoutTokenResult.data as {
          logout_token: string;
        };
        const logoutResult = await baseQuery({
          url: `user/logout?_format=json&token=${logoutTokenData.logout_token}`,
          headers: getMutationHeaders({ type: "json", sessionToken }),
          method: "POST",
        });
        if (logoutResult.meta.response.status === 204) {
          return { data: "logout successful" };
        } else {
          return {
            error: {
              status: logoutResult.meta.response.status,
              data: "logout failed",
            },
          };
        }
      },
      invalidatesTags: [tags.IS_AUTHENTICATED],
    }),
    passReset: builder.mutation({
      query: ({ sessionToken, currentUserId, passResetToken, pass }) => ({
        url: `jsonapi/user/user/${currentUserId}?pass-reset-token=${passResetToken}`,
        method: "PATCH",
        headers: getMutationHeaders({ type: "jsonapi", sessionToken }),
        body: JSON.stringify({
          data: {
            type: "user--user",
            id: currentUserId,
            attributes: {
              pass: {
                value: pass,
              },
            },
          },
        }),
      }),
      invalidatesTags: [tags.SESSION_TOKEN],
    }),
    requestPassReset: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: `user/password?_format=json`,
        method: "POST",
        headers: getMutationHeaders({ type: "json" }),
        body: {
          mail: email,
        },
      }),
    }),
    sessionToken: builder.query<string, void>({
      query: () => sessionTokenQuery,
      providesTags: [tags.IS_AUTHENTICATED, tags.SESSION_TOKEN],
    }),
    updateUser: builder.mutation<
      any,
      { sessionToken: string; id: string; payload: object }
    >({
      query: ({ sessionToken, id, payload }) => ({
        url: `jsonapi/user/user/${id}`,
        method: "PATCH",
        headers: getMutationHeaders({ type: "jsonapi", sessionToken }),
        body: JSON.stringify({
          data: {
            type: "user--user",
            id,
            ...payload,
          },
        }),
      }),
      // session token changes if the password was updated.
      invalidatesTags: [tags.SESSION_TOKEN, tags.USER],
    }),
    user: builder.query<User, string>({
      query: (uuid) => ({
        url: `jsonapi/user/user/${uuid}`,
      }),
      transformResponse: (response: { data: User }) => {
        return response.data;
      },
      providesTags: [tags.USER],
    }),
  }),
});

export const {
  useSessionTokenQuery,
  useCurrentUserQuery,
  useUserQuery,
  useRequestPassResetMutation,
  useUpdateUserMutation,
  useCreateAccountMutation,
  usePassResetMutation,
  useLoginMutation,
  useLogoutMutation,
} = userApiSlice;
