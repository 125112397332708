import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavbarState {
  isShownMobileMainMenu: boolean;
  isShownMobileSolutionMenu: boolean;
}

const initialState: NavbarState = {
  isShownMobileMainMenu: false,
  isShownMobileSolutionMenu: false,
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    onClickMobileMainMenuToggle(
      state,
      action: PayloadAction<{ isShownMobileMainMenu: boolean }>
    ) {
      state.isShownMobileMainMenu = action.payload.isShownMobileMainMenu;
      state.isShownMobileSolutionMenu = false;
    },
    mobileSolutionMenuToggle(
      state,
      action: PayloadAction<{ isShownMobileSolutionMenu: boolean }>
    ) {
      state.isShownMobileSolutionMenu =
        action.payload.isShownMobileSolutionMenu;
      state.isShownMobileMainMenu = false;
    },
  },
});

export const { onClickMobileMainMenuToggle, mobileSolutionMenuToggle } =
  navbarSlice.actions;
