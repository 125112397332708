import React from "react";
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import { Toaster } from "react-hot-toast";
import GoogleAnalytics from "./src/components/GoogleAnalytics";
import Sentry from "./src/components/Sentry";
import { Provider } from "react-redux";
import { store } from "./src/app/store";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return (
    <>
      <GoogleAnalytics
        uri={
          props.location.pathname + props.location.search + props.location.hash
        }
      />
      <Sentry />
      <Toaster
        containerStyle={{
          top: 60,
        }}
        toastOptions={{
          duration: 7000,
          style: {
            borderColor: "#D3D3D3",
            borderWidth: 1,
          },
        }}
      />
      {element}
    </>
  );
};

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
