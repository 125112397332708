import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CheckoutState {
  email: string;
  marketingConsent: boolean;
}

const initialState: CheckoutState = {
  email: "",
  marketingConsent: false,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    emailStepCompleted(
      state,
      action: PayloadAction<{ email: string; marketingConsent: boolean }>
    ) {
      return {
        ...state,
        email: action.payload.email,
        marketingConsent: action.payload.marketingConsent,
      };
    },
  },
});

export const { emailStepCompleted } = checkoutSlice.actions;
