import { Script } from "gatsby";
import React, { useEffect } from "react";
import { useCurrentUserQuery } from "../features/user/userApiSlice";
import * as Sentry from "@sentry/gatsby";

interface GoogleAnalyticsProps {
  uri: string;
}

const GoogleAnalytics = ({ uri }: GoogleAnalyticsProps) => {
  const { data: currentUser, isSuccess, isFetching } = useCurrentUserQuery();

  useEffect(() => {
    if (isSuccess && isFetching === false && typeof uri === "string") {
      const timer = setInterval(() => {
        if (typeof gtag === "function") {
          if (currentUser?.is_authenticated) {
            if (currentUser.data.uid !== 1) {
              gtag("set", "user_id", String(currentUser.data.uid));
              gtag("event", "page_view", {
                page_path: uri,
              });
            }
          } else {
            gtag("set", "user_id", null);
            gtag("event", "page_view", {
              page_path: uri,
            });
          }

          clearInterval(timer);
        } else {
          const message = "gtag not a function yet";
          console.error(message);
          Sentry.captureMessage(message, "warning");
        }
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isSuccess, isFetching, uri, currentUser?.is_authenticated]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG}`}
        strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script id="gtag-config" strategy="off-main-thread">
        {`
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() { window.dataLayer.push(arguments); }
    gtag("js", new Date());
    gtag('config', '${process.env.GATSBY_GTAG}', { send_page_view: false });
  `}
      </Script>
    </>
  );
};

export default GoogleAnalytics;
